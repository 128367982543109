import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export const LazyImage = ({ src, alt, placeholder, width, height, errorPlaceholder }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasStartedLoading, setHasStartedLoading] = useState(false);
  const timeoutRef = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const handleLoad = () => {
    clearTimeout(timeoutRef.current);
    setLoaded(true);
    setError(false);
  };

  const handleError = (e) => {
    console.error(`Error loading image: ${src}`);
    clearTimeout(timeoutRef.current);
    setError(true);
  };

  useEffect(() => {
    if (inView && !loaded && !error && !hasStartedLoading) {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(true);
        setHasStartedLoading(true);
      }, 300); // 设定防抖等待时间为300毫秒
    } else {
      clearTimeout(timeoutRef.current);
      if (!hasStartedLoading) {
        setIsVisible(false);
      }
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [inView, loaded, error, hasStartedLoading]);

  useEffect(() => {
    if (isVisible && !loaded && !error) {
      timeoutRef.current = setTimeout(() => {
        if (!loaded) {
          console.error(`Image load timeout: ${src}`);
          setError(true);
        }
      }, 5000); // 超时设定为5秒
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isVisible, loaded, error, src]);

  return (
    <div className="lazy-image-wrapper mx-auto" ref={ref}>
      {!loaded && !error && <img src={placeholder} alt="placeholder" className="placeholder w-full" />}
      {error && (
        <h2 className="error-placeholder mx-auto text-center">{errorPlaceholder}</h2>
      )}
      {isVisible && (
        <img
          width={width}
          height={height}
          src={src}
          alt={alt}
          className={`lazy-image w-full ${loaded ? 'loaded block' : 'loading none'}`}
          onLoad={handleLoad}
          onError={handleError}
          style={{ display: loaded && !error ? 'block' : 'none' }}
          
        />
      )}
    </div>
  );
};

const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export const placeholder = (w, h) =>  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`;

export const errorPlaceholder = (w, h, message) => (
  <div className="px-4 py-10 break-words border-2" style={{ width: `${w}`, height: `${h}` }}>
    {message || '加载错误'}
  </div>
);