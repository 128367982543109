import React, { useEffect, useState, useCallback } from 'react';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const RelateVideo = ({ videoId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  
  const fetchData = useCallback(async () => {

    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

  try {

    await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axios.get(`/relatevideo/${videoId}`);
    var shuzudata = [];
    if (response.data.status === 200) {
        let data = response.data.data;
        // 遍历并修改每一条数据
        const updatedData = data.map((item) => {
          // 深拷贝 item 或直接修改
          let newItem = { ...item };
            // 解析密文和 IV
            const cipherText = CryptoJS.enc.Base64.parse(newItem.k1); 
            const iv = CryptoJS.enc.Base64.parse(newItem.v2); 
            // 使用固定密钥进行解密
            const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
            // 解密操作
            const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
            let plainText = decrypted.toString(CryptoJS.enc.Utf8);
            plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
            const jsonData = JSON.parse(plainText);
            shuzudata.push(jsonData)
          return newItem; // 返回修改后的 item
      });
        setData(shuzudata);
      } else {
        console.error('Failed to fetch related videos:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching Home data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [videoId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return (
      <ErrorRetry title={'推荐视频'} onClick={fetchData} />
    );
  }

  return (
    <>
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">
        推荐视频
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
        {data.map((item) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}/`} className="break-inside-avoid mb-4">
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(300, 200)}
                errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                width={300}
                height={200}
              />
              <p className="text-center mt-2">{item.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default RelateVideo;
