import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import { BannerAds, TextAds } from './AdsComponent';

const Header = () => {
  return (
    <header className="flex flex-wrap items-center justify-start">
      <NavLogo />
      <NavMenu />
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">
        发任意内容回复最新地址 <a href="mailto:dz@zhao5g.com?subject=最新地址找回"><b> dz@zhao5g.com </b></a><br></br><br></br><a href="https://3u36uh.lol" targer="_blank"><b>点此进入最新地址发布页（加入书签防迷路）</b></a>
      </div>
      <TextAds />
    </header>
  );
};

const NavLogo = () => (
  <div className="w-full flex items-center justify-left">
    <Logo />
    <div className="slogan h-12 flex items-center justify-right text-xs">
      <NavLink className="domainslogn_palyer" href="/">15g.app - 天天5G天天爽</NavLink>      
    </div>
  </div>
);

const NavMenu = () => (
  <nav className="w-full my-2">
    <ul className="w-full text-sm nav h-8 flex items-center justify-center text-center text-tw">
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/大陆/">大陆</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/日韩/">日韩</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/欧美/">欧美</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/三级/">三级</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/动漫/">动漫</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/色图/">色图</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/漫画/">漫画</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/小说/">小说</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-tw text-bg hover:bg-tw hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tw hover:text-tb'} to="/h/短片/">短片</NavLink></li>
        <li className="w-14 h-full"><NavLink className="flex h-full items-center justify-center bg-sc text-tw hover:bg-tw hover:text-sc" to="/search/">搜索</NavLink></li>
    </ul>
  </nav>
);

export default Header;
