import React, { useEffect, useState, useCallback } from 'react';
import CryptoJS from 'crypto-js';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ThumberList from '../components/ThumberList';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import NoDetail from '../components/Nodetail';
import Meta from '../components/Meta';
import Pagination from '../components/Pagination'; 
import {BannerAds,GridAds } from '../components/AdsComponent';

const validBasePaths = ['h', 'tags', 'series'];

const CategoryList = () => {
  const { basePath, typename, page = 1 } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await axios.get(`/list_${basePath}/${typename}/${page}`);
      let shuzudata = [];
      if (response.data.status === 200) {
        let data = response.data.data;
        // 遍历并修改每一条数据
        let updatedData = data.map((item) => {
          // 解析密文和 IV
          const cipherText = CryptoJS.enc.Base64.parse(item.k1); 
          const iv = CryptoJS.enc.Base64.parse(item.v2); 
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          let jsonData = null;
          try {
            jsonData = JSON.parse(plainText); // 解析成 JSON
            shuzudata.push(jsonData);
          } catch (e) {
            console.error("JSON 解析失败:", e);
          }
          return item; // 返回修改后的 item
        });
        setData(shuzudata);
        setIsLastPage(response.data.data.length < 16); // 判断是否为最后一页
      } else {
        console.error('Failed to fetch data:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [basePath, typename, page]); // Ensure the data fetch is triggered by changes in the route params

  useEffect(() => {
    console.log("datadata",data)
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return <ErrorRetry title={'列表数据'} onClick={fetchData} />;
  }

  return (
    <>
      <Meta title={`${typename}-5G影院`} description={`欢迎来到5G影院，这里是精彩的${typename}内容列表页面，请选择你所喜欢的内容欣赏吧！`} keywords={`${typename}, 5G影院`} />
      <BannerAds />
      <GridAds />
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">
        {typename} 列表
      </div>
      <ThumberList data={data} />
      <Pagination basePath={basePath} typename={typename} currentPage={Number(page)} isLastPage={isLastPage} />
    </>
  );
};

export default CategoryList;
