import React from 'react';
import { Outlet } from 'react-router-dom';

const NoHeaderFooterLayout = ({ children }) => {
  return (
    <main className="mb-4">
      <Outlet />
    </main>
  );
};

export default NoHeaderFooterLayout;