import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import {BannerAds,GridAds } from '../components/AdsComponent';

const Series = () => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchSeries = async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
      await new Promise(resolve => setTimeout(resolve, 500));

     var series = [
      "{'v1':'t59iemrtNBwLTO5MkZhI/aPIQlysAX9xFiPtS96WnYM=','k1':'WpDnq8ZN1jztbtNNNkU+Cg=='}",
      "{'v1':'WFIfTAv430Kak/AQXoqrbAtafBpSqG1sVR1nrsPJhhg=','k1':'SjcmDAwKeJaTLdg7TmrX1w=='}",
      "{'v1':'S+piPlaQ1BMNxPyiboT35IN8hM9YMOWmHuAwx4RO+lGcipagjOyPmhztXf6H+Z23','k1':'ZO6icF9KXx1X7JC1ia/dPA=='}",
      "{'v1':'X0LehmTtPz0ZtWx5Vw3BBMeWepSCLnxNShHicP/yZ47hTQbbS6FFPSwSD2HAL/Wn','k1':'2DHTAKTdUokCC7fdIF8RyQ=='}",
      "{'v1':'rl6OwSMwDlyy1Ag3NLJwQ8ZvF5i8eCHAorFz21ZUcQQ=','k1':'58vgD4ItHM+tYW4ZATHhtA=='}",
      "{'v1':'QTHAuWGohISHv3RY7OP0YwwTGkW3inaWeqtysRZjGQg=','k1':'1Hq/Lcjq+mMM5qfQVDXrEA=='}",
      "{'v1':'nETjfGcKWFhmPnd4zI6rjpFg+5LInFUVQOdiUH6FAJew40+kcQMCb6VyGd5MiWmz','k1':'IsQQZy0FRGH6mogQu46XPg=='}",
      "{'v1':'E5TBNk2WadAdQ2EZ2eEgi4neMv8aTJ4gf1nE5VKml+Y=','k1':'m4qxEYmUyHY+OeVcO4yKrw=='}",
      "{'v1':'OWCkSLKLUEUe848T/XCXyqGRUrJaIhubA+pLmvVdM9g=','k1':'ZBKw4R68U32w8r7US2mHgQ=='}",
      "{'v1':'sqhWyyX+36t3grRDEQE0w9EV1XzO6i0m1+P1pv9ci5k=','k1':'0+jxW/He5GMm8rusz7JnaA=='}",
      "{'v1':'fFeub/qOcWgcsatj1Ssz0x/uSwWRmydMdzwFvgtofsg=','k1':'Z4uwwzO+fWBIDMKVENtLXQ=='}",
      "{'v1':'PmypAi6e6onX2UNsAuaQFFvLYunFoFbtlmyEAR9Hc00=','k1':'yBBJL3kRt4AJijzBYG5CfQ=='}",
      "{'v1':'wmk7zCiF/RHT7HOejgBXscY9H9PvvO8J5S5ExGPNHRM=','k1':'JuYjci1u7xWJM0tOrH/0Hg=='}",
      "{'v1':'YNn2TBIYzg5zCRYuzmI6Ygl0cEASYwVNG1wzFABFNzw=','k1':'LTtTzmGxUUYIdoa7ga/qtw=='}",
      "{'v1':'pMbRI9AvuKz5dKK+mfocCFoWQY1uVEoEVJ4yUg2Vf1w=','k1':'90xfS5++PlETi/7FnWtn+w=='}",
      "{'v1':'eNVh2xi00eG2D6UX+qt3Sw6jbmt1fVOEze3rrM8EpMGS0hofFnmh0vq03cNAKAFy','k1':'DBzKykdlgSmlmtjm2sVz0g=='}",
      "{'v1':'qTYOAUtfUjaLjWM5ti6/yjlf5MWJrrflmbXDmkP7eFU=','k1':'6Sll1XcQ1hDSBJ2sCRWpRw=='}",
      "{'v1':'inKTL1j9qHwc69HH1P4tjmz4K72sI9s9wgXJwgLmLGo=','k1':'cMJBFzds7E8jDLBZapLwOA=='}",
      "{'v1':'Y1AUr5Hkw6np79g5uRR5OS4P2U1bKOCQ0SOpk6lTeqM=','k1':'Ma2YNuW0Mo5avPF9qe4sZA=='}",
      "{'v1':'cOy6jJEsqv+MNhIAGRr1QXJCBslomGqKLHokx6+GIRCajTy4GTMTy6ofzO9VSrLZ','k1':'9Lh+GNm3a2pTyYEuqifm+w=='}",
      "{'v1':'jhMiRas7e2LWeqCSjs+O8p6bNcbHYrlOVV9vwMvtpfE=','k1':'kZVl4e7fD80WGW/pIWMzAQ=='}",
      "{'v1':'pcgaQJ2JdAAPoZYIkPN1ELe4NU8FdStIIWiR4x5rdPlRdS89XtmO8IJzpjCnVgz/NL3VITkChdTeVUjdXpkMKA==','k1':'ARSTU2ilNJ+tdLd32gXmDg=='}",
      "{'v1':'q4L6hWAWKHwb9keAjfy2QdQ72EnV6wnMgblwpqd4uIY=','k1':'pNVbFYmiEFYnuZPBN9NT7g=='}",
      "{'v1':'KgvcJQsz376ehIjXkCcqLJQq2f7kqEk82j+7ubNI/g4=','k1':'2jL7uzHrX767Uf4XaeRXVA=='}",
      "{'v1':'TCaHcxoU7wb3G2zSfXETvHJ0tZcboxbyzSabrFwXdSw=','k1':'6SgK14g1zc2sYgGuo4AAqw=='}",
      "{'v1':'NAiyQxclALlA8XgWVkglyGeOm0KD5p0elBlvlzLwUDY=','k1':'3SKaiuP8x9Ki4wg7nbzwPQ=='}",
      "{'v1':'RrP766Hpr41OAuO0O6wUlOqyhVPTF4hUz6ROZjEswaWpZy5tVqLEQFJi+h5yj0xE','k1':'V87g3JZl6RypkyF5rKqJcQ=='}",
      "{'v1':'QVUfXG+N/q1tRj7GDDxKvo6KV9B6a26A6HDYBANTJH8=','k1':'bf7H3BCa4X+XtEKKtxdGYw=='}",
      "{'v1':'wXdkNtx2dFMgcjrFprJEfVeiMw65YW1glJ4oXvgme3A=','k1':'pNLX49c4TCLnE5+26G7DPg=='}",
      "{'v1':'Mr8NmS+/o80LxHDJwDeU0R09dvfxBEjpn9NnRRP2Ii7XZXesEiVK+KGLzoiRWk1Z','k1':'dJCbXJbVxfaCyejnk6Q6VA=='}",
      "{'v1':'joj9Rr9FraD9yhcFzERl2cMKq0gWQf83IGS5zaL53ld9rPzFSexfW0nfdnxYxQsf','k1':'165MYolPhngYvlIKR0ULGg=='}",
      "{'v1':'IRFRtLO9qByfk3CAy6g932WEst1eNtkz5hOzTZ/cOGoQhuyDQoGL0bG37DYR6dU5','k1':'ihspQ0jcdhp3fCpC5ckbBA=='}",
      "{'v1':'SY4SXpFMdE7IgXrOXd1xtgNeiz0oUpwjaAq73QajdoFRHrAgkkX53onRCkXD9xYm','k1':'QpPgf8Lof0hQ69S4KYLMOw=='}",
      "{'v1':'7eYioD9EiHxJ417KqLvca3gnjEKYxlUjoFHala812t0=','k1':'7e10VUPFZhepcRei1ahCkQ=='}",
      "{'v1':'Chcp/uTw7jrC+7FSmp5u5lU6oYQdkVXqd4PK9n5GYY4=','k1':'yew/FAhBBqFLy9LwUHhEhQ=='}",
      "{'v1':'OL/Xy/KjWW5MZx/7gFXn1jg4scTHytoj3DZVbwFiZrA=','k1':'MKbtX1dqiveKT13CYMQOwQ=='}",
      "{'v1':'3Br4Z0Xw4yfWxcw2fye+JPeGxw/+RiHQ5K4NtbU5pk0=','k1':'NLwX3pHHhFJdJJ+LS2vAuA=='}",
      "{'v1':'riiujaKXiyC6+A//picsdz2WDD7LdgSMOn51XboGcmQ=','k1':'H1kfsnbWKypDa+j/6PMzcA=='}",
      "{'v1':'G4qE5wOTPAasWnOE1NkKE2BcD6GOWWp7CKUPmOo9n7w=','k1':'vpl7w/LWT/Ol8HF+oPD2pg=='}",
      "{'v1':'76+ECG8XJU8usIYjBqzqSJjwkVF3eAYnEiCnFp7Tbzs=','k1':'pEnAiKx4hGnubSqyvoBqTw=='}",
      "{'v1':'aAfixCqWCbmcbzNc+I548OHpxB5zFmaYB4B5ZZqTtiw=','k1':'LQjXBkJxiaOqaPPRjKDlAg=='}",
      "{'v1':'u7dgFpWo3O9Ys7ykU9a+Mh8dSS7/70UmGyoKpDuq3jg=','k1':'OCUCsudcjDEkdRjxqHs0/w=='}",
      "{'v1':'6PDYAi4yYp34FYj+iFPe4mFT/ncTCmOA7PfjJPIFXlk=','k1':'H+Fqq7UGVBTJG30fgrp2wA=='}",
      "{'v1':'SpE4kknssMmZ7vk52/PJg1WpXX7/f0nyDpY0+vIinDo=','k1':'1idygjQwJbanCmHei+J49w=='}",
      "{'v1':'PdXZpuu5At79mNQo2u30giaMdgz6xS1kIoH/7eIBwlw=','k1':'ziNyDXlswm0DshqFo4C/9Q=='}",
      "{'v1':'nXE2kuzoGLgSFm/Jon1+6OjX+mfhnY/cWJEXAIlj5Qc=','k1':'TBOm7teUIXOFM8TOC1xZSA=='}",
      "{'v1':'N4xhm15SOBYCT43zgYp0rW9q+OAZMs5cFBQw11NzwNY=','k1':'94kUykXx37V/Jab2VSesEA=='}",
      "{'v1':'o8zegeQ0P+FDsxPeGyKO1YQL5txKJGftuq1pMBU8OH0=','k1':'Wuby9xV08MMTBSw+8Y+g8A=='}",
      "{'v1':'6Hj1h3dPCrq+yZv7TapPbFtaeWEqau4b6KbVqAs5bCI=','k1':'m+83rfXailGI7wdSO5h/AQ=='}",
      "{'v1':'w4dSCuoWCsK0osHyMh+iNusUNz8lMZQYNCdk5CbzbWc=','k1':'6fZVdWhopfiCSTjd31hSOg=='}",
      "{'v1':'StLqQCH4ea8FPYVuIVRWv0zbx3a8c8iEVpzBCTWQNw4=','k1':'FOcmj6kM8fFZvxV1ChepgA=='}",
      "{'v1':'s2+SIdAcdLKxmpb7qK88XtkJ2pHT+0P1d4LjVXzP0fM=','k1':'OTfJS+VjdcySFqaWYqRsmQ=='}",
      "{'v1':'igBQqr0r4QJ4vOX7zWFmhauD0qLyOE+Vh8xxCUUnjBU=','k1':'8usGPaWydwqScLH+vfWjnQ=='}",
      "{'v1':'GC8zV8YaQEieTBFhll5R0oMfQtmZ11PUZVb7UiwRavQ=','k1':'qbBQSQyPpgzip8BRrhja8g=='}",
      "{'v1':'TybxBKrZ7ZXQZRq0vnaTL1tSJCtjBzfeudlQWSbyuhU=','k1':'4YcK+U8TU4CQGjPrLLvW6Q=='}",
      "{'v1':'O39MpB7rk8OMZEi/DH5qAvUKexIRUP0NpYYG9nnuC5g=','k1':'6ig+ro+e4u7hvc8ln+M1LA=='}",
      "{'v1':'571J5tIFRyP8rVwnCR9I884cu+3X0vGiKVTZJUj50fU=','k1':'cJ/L+E2ZDgV5XFV938syqg=='}",
      "{'v1':'nHToM8WOdtf3iF1G40BxPDhD2eUE6jKQ7FQ7U7w5sdk=','k1':'GyvEofycYeHJrx71rcpCXw=='}",
      "{'v1':'w33QdOpaFCL5qbDfFbBaKKxpST2jZpFw/C07iZNyNvg=','k1':'y/t+RSw+TpE0Y5IJqHOlGA=='}",
      "{'v1':'E0v/YM7W+8QfXQsOeOIQJfMS21QDVyBJTGOUwTWel+c=','k1':'l74YRPJe5UgqRl3Hc9kg8w=='}",
      "{'v1':'0Zz9yg0FQrT0g5GeoFebCaSjXO+f7uBpmgzb/9S7sm8=','k1':'8V1Cpgc5WTZDR6pysEfX0A=='}",
      "{'v1':'K88aKaqMh2TJF8I/M41otxyGLQx+OQDR5VTPhldFpr4=','k1':'yQLFT1uwG3PRwHd69ZPcsA=='}",
      "{'v1':'QWwq76Y9GZI6rSID7gjvTS1hSsJujT6y7641spDxhf4=','k1':'VpSKBvg94q8zdxv0g3sqGw=='}",
      "{'v1':'tweQiM0XvAGX5iPH6Vv8Chc27zPDFmkZjX1z9oxZkew=','k1':'C/ywFIjw/fy6+/kEJmW5FQ=='}",
      "{'v1':'zxMfrkom5h8UoBmsMjc5kkjVGvjBKJ/h2bVjzpvyqdY=','k1':'uqYCxX5yzgDZCc5fJqvfcg=='}",
      "{'v1':'Yn8AfXjpfGPoNGlbwK53zl22R51U/ZEkmZdaN+NT7vk=','k1':'c93zqA+DCxC3PgbimBkU4g=='}",
      "{'v1':'eEwaSr5meue7L/PeDveTYCg79vgW0kDY5/hmeKqlsqQ=','k1':'19SEfaRlJmEC/f84ANWDTQ=='}",
      "{'v1':'YtJjiQAnptLSsveYjKGXv/+dfYpvVLOmRKp8XX9YOWY=','k1':'Lbb90/8wAaHf4/4M48WeBg=='}",
      "{'v1':'3b8K35FtWTzfFi0ufUpM9ymtv3ffJnzQKy/eMg1hR7E=','k1':'1eyOVjV2yZtXSobW96RFtA=='}",
      "{'v1':'MNgUd9An4zu8wboLDp3quaRDXyUIDTiopIiaFyZoe2M=','k1':'Azq0jcpDKNs1z+gMLOHsig=='}",
      "{'v1':'J4c7oPKdfnGKOlJqfEHmjl5al2F/TWHYrsnZIHFetX4=','k1':'NraNRoFG/GQ0DYkj8FuvrQ=='}",
      "{'v1':'7gh+cLgoCExLZy4BchzKNYfHJQjPfcM6OaHq9/eCPfw=','k1':'26fnUcnsmDTYQNDRATjHzw=='}",
      "{'v1':'qRnvSuyr5B+4Y2NGF/SYXl8qHrhzukHQROc7GMkmJn4=','k1':'MeOwQ85XaWqnJ4swFsa4Jg=='}",
      "{'v1':'nvG5SpnJXADlGdyQjJzffigBUVyOQw0HyI5n/04naiM=','k1':'B5UEPG1cT9txgaOHsCyZjA=='}",
      "{'v1':'OEhq2K7UK4stw0otdEXvQsxxp9CSAd7e48XO1lCWAYE=','k1':'c3bqzPiGYYEh5BtR1wtY8Q=='}",
      "{'v1':'5Gi/7qESq5J3iQjhKrAsvaLYpioFkQ+Glo2XeWDl/Ao=','k1':'NBtUKkSc7K8zQEsbnShH4w=='}",
      "{'v1':'gI32Xth297b35g8FEhcUwnyqgC3viYOG6ahhpLVt/20=','k1':'luFcMyaAV0AKHJYzGnXZOA=='}",
      "{'v1':'IAs1X5zySjxKR73GTG+r7hrVyXG5B3glWQoMpo/kKJs=','k1':'l3MyOr3+lAhKsYVICN+TzQ=='}",
      "{'v1':'UzbVD92J6P1ZrObTro4fYBF5pdiwBV4XEkRJk6aHTJs=','k1':'T1xjX+6TD66q48jBhrGvKQ=='}",
      "{'v1':'NU6pWzzriALPTgGMmUp7sv9gqq3akHyq9Uo6K2qKW6Q=','k1':'YMtu4hmL3cftFSW5/b7yjg=='}",
      "{'v1':'h8mAD8vh//XLeF8TI7D4ZyCVw5Brb49QYJTn4T6wXY0=','k1':'MFCpHbiZQ7m5DA3vPeDYIg=='}",
      "{'v1':'al8IblTJHT6/62qFA2itR4GlmA0JLrkRlSJjz/WwPKI=','k1':'/EByoV1WmQlfrphlHNQt4g=='}",
      "{'v1':'aDzBbk7Lw5l2laLHX3ZZ+i0kjwLxDHa8YS/NgqBDSH8=','k1':'bZAwfcGajLrQa/Hp0Ww9Kg=='}",
      "{'v1':'7j6NjcidsRGrJrp+KLdIyOQt4GpELCZKjFCNNM0ygHE=','k1':'B7DPMZ6rjZywItJifLMQOA=='}",
      "{'v1':'WbJPptZvaBNFJCryu9OYy9ZTIvEzRtSp4m2quEdvoNU=','k1':'p6pyusbHF9MgPMrMcz+++Q=='}",
      "{'v1':'nkG+VfaiAKcDZiBTWd9MJWkquyHse1QMYOkcTI31Y+yXaHGEL7gyv9T++1HhxUUbOO+HLMQX8clK/2GI2e4pQw==','k1':'RtkDvsz9BywMTMFQDyc4eQ=='}",
      "{'v1':'zhawiA3DC9WIs8q8jpmIvaWdoPKc7MIXycAjulIbu2A=','k1':'/79UVIcgdN3j/nF5WEEiDw=='}",
      "{'v1':'fAEks2aXZz5sQrr72jWZ78PPa4UETwiOz6sIAxPU1ZY=','k1':'l0ow+WoEkBsZkotl3EKj4g=='}",
      "{'v1':'ctJ8A351v6Rt5ru3CR9uHn/evjlBqUgBCjVVnRixj+Y=','k1':'sG35dVOcMJIldFUmJRkCqg=='}",
      "{'v1':'7eclZaN05qo1Km+xkOh+tyMustvHzJjEWohImw3YNuQ=','k1':'Z7XOp3bs4BS5vV7qjSqRBA=='}",
      "{'v1':'w/uvuGRF0dI0pTMkUXVeqlVjnoIxTyOC7C1mReaXBpU=','k1':'S2sWp/2OsAh1rKqbqejC9Q=='}",
      "{'v1':'RpbPshHQLNSC4AMBSBi6cbTSbSbeAW050C2BNxU0BnE=','k1':'nfpIxhZ6RJ3+KS6TlC3XaQ=='}",
      "{'v1':'2eilsPSoFyXqzQHKJSRdHnBRU5mtCpSfgUEGFw3O/7E=','k1':'DvWCNh02VKq35v0+HrUh7w=='}",
      "{'v1':'WdLYGhQ0yqZRq8wMJ7hZrSv9UUwgUs/pfMO4IuUcpsqS+91cDL5QwTDP/XVHccuO','k1':'5EVPOx2QdGNctM3O0Dhi3w=='}",
      "{'v1':'M+MBeZS2H5MItSBw26AEA0725VCv/MA4Kn4AJkqJb/Y=','k1':'lANaCLjae9ds508eLGCDTA=='}",
      "{'v1':'l3THx18oUkS+7l9DEbyjfAEdTEOIQQ6+dUHRZdnqfaM=','k1':'c0f5qveTyHhIqKYVW73pMA=='}",
      "{'v1':'UoffVWSg9eCiq4djcKKC99Fdv86OHqFnIK4pxxtXo9E=','k1':'UKhhzXE/MPqKqNswvKoKZQ=='}",
      "{'v1':'bs6kPf8vdsDE36obCJThykWzT5oqwYrSHqyrFwkagHU=','k1':'/ncd0diYUzdoiuy+CnzgaA=='}",
      "{'v1':'bEduwoQ5RMOWJbLaML7HSCJz63I8hjQoXZxNNfIcO/7hZNg8Yfp/+xrTxfjhhJ2g','k1':'STkx4AZ2QOYuJhF+Pz3xvg=='}",
      "{'v1':'E7mbSUtbAa5zmGUyl2nrxz26tKouv0VXFWavkrxy8BlMRHqh+07QYzJ0/Eecr9AL','k1':'x3RGWGr/k33JHRH01Ju2pA=='}",
      "{'v1':'t5ihgp3z+AXVLmwoXVlhq4rZixjdOGGi9mALxA9h+F4=','k1':'FIp7jVd5oN1mIYyAqrbPbw=='}",
      "{'v1':'sJOZ5oSL6p9GfF0JXQ8+2eEM7Uiasy+oUqAfaCgd8mU=','k1':'gyV7AAHUMBuyQrGep4lZDA=='}",
      "{'v1':'/eFaa6MZXQMuYeJOR/C7EZadMCjb892PfvgKPRYosuk=','k1':'RSC4si3uSfedFAK8QwZ8MQ=='}",
      "{'v1':'OMJJ9yEaHPbBCdhuzT00j5swOG74xwmrcAYl8rql2Cc=','k1':'Lo12uNjrYbmDI4mlhHEseg=='}",
      "{'v1':'m2B0V7byOkKpXZ8k60ipO39qZhQx9BQTKPD39w2PoFM=','k1':'z/dFq5694iejHUNckhITEw=='}",
      "{'v1':'MSxR4SWmmhrVQ1ZdPTzwYLg9usIEr/rlR41RQe1xwWY31L3TUVroD+2sNWFmhSN9','k1':'4qaNGGDof1paW1lvxZQz9Q=='}",
      "{'v1':'kKkEiZb9KMVaSH2HzhueeBGmsSPqjPGN1jKcfPHSqdM=','k1':'8PGP24Y0qd2/RFF071+ahQ=='}",
      "{'v1':'En5wlEF2J0XSL8oZmL6p7A85LmY2Qunix3oJfVvBR/zoDWZMinRVLOdoaldBv33e','k1':'0lgvpkdveeQhhUEeh+zOKA=='}",
      "{'v1':'pX0d5pTz6xc4IGvHJtIka1FAWeNzpaNeF8z3b4gx4Js=','k1':'CZDWz04Sa/WP5dW80tUVyQ=='}",
      "{'v1':'HmeLQMD+AcnWzyMSC6Pr/Z+7fR8P2DscNLNaRaVeIyI=','k1':'ScL8lwexgtIINtnnyMCDow=='}",
      "{'v1':'DkXgaIDuJaviJWZivYpM6jGicjaErWvQ2iZY1q7NuVFuUItQZL+mmKKj/0D9PpwQ','k1':'Tauwpsc+CVoNffZPan3QCQ=='}",
      "{'v1':'uEi4SBYds49LC/rOCYaznd9Nv/RL6Bw8pOYW+bUg8hM=','k1':'sTx6MQITtivGftqlUglkKQ=='}",
      "{'v1':'qeYteZf9uLjkq6e/UFNf6JQyjwzh99WcHItrXv9oenA=','k1':'+PoDFKNMiB8Sfu80P1dTwA=='}",
      "{'v1':'2C8wcues/g+i1QnbtMffAa7PpI03NBhF5PG0exSFsrc=','k1':'v6VgnyqETcArzKEOh50YSA=='}",
      "{'v1':'XLekZGrzP61OtInJ2SVSSXkpCqnPH/y5Z7ff4/auOB8=','k1':'6CtRSIePXVCmGilOC9kT8w=='}",
      "{'v1':'LZ9wLpXChW9d5zgR4FDkRcGWoehtCIKwUxTj77Lg/xo=','k1':'4gghdleZwoilSKIeFIhAhw=='}",
      "{'v1':'DqyOnZHTi/QPmia/pCuDoWhJJivlXnKuoJSko+p61YU=','k1':'9hSrRhDGR5HJJ7fyYzBGtw=='}",
      "{'v1':'y+eLbg82PFwt3Qc1USam0HNFA+rQHxYI0vASuMgGnpc=','k1':'gO+FpbKkQAoO3xrUCd8U6w=='}",
      "{'v1':'4iZy7YG7iWX7Lu6NWUsmGnOB77I2LtSH0h6mYNGXB0uCn0Hd/3sOkE8K64SKV6eP','k1':'oIyKOSriuQ+nW2DcM4r2AA=='}",
      "{'v1':'EbWN4ZeMcjWmI4AFBG3DG7HVZhLSnc99FW5zQhLOxAc=','k1':'RTnfIArohVKp1UwdP9Mkbg=='}",
      "{'v1':'K8mWlComi/aT7WobKJwVLOpqNRezt759fJy4snMozFkjb7wwxkXIz1CpN14rTwOH','k1':'nuRvRsKjXsHvTr84WFlmqA=='}",
      "{'v1':'7FFXE5bk6KggVg3vdYSRHotLudgkljdq+wZRTKwIb1E=','k1':'MNnKHUZsekjCArrfDvq5tA=='}",
      "{'v1':'dU/aGaRi1RqevLVZcabXXztFtLMYlsfRabwOGYPuOqk=','k1':'vR1r0FWcIxUHN1C70YbNOQ=='}",
      "{'v1':'CX3uoTrGatpzzgXJJSXFr+Mqd5cB+yWBmIq+AlBCPCp7OddboBdF2EKqkSDTXkLl','k1':'RFdYHZIEzR00ENYMsHj7vQ=='}",
      "{'v1':'NWMeEbB9E6Ivyw1v+xBi6FQxw08bM81meHx5pJ3Pve6e/u64+0XmKLNs3pznOs3/','k1':'NhX+Y/ARiiA4wy0/utMGsA=='}",
      "{'v1':'ETIjmznRDRk0xXJ3fQgj360yr5ldHq6RhNE8Ekbvt1c=','k1':'8GMwgU8G9tCshv5H3Pu8Uw=='}",
      "{'v1':'ZA++NHN5pit97WuEs5O0aY1JZyzEBWvX5WiUgcCVVqtkOFcHhiJXRpEPTM8CqPXX','k1':'SBCqGwKI2cquyeNzf1FL5A=='}",
      "{'v1':'NbJXPGK1i/m8JsMSvv2DznMRHtkMnFFFqysLo7CsK6S3+04b6+PBC5g2sIczLxpW','k1':'KipBNtjKq5QAm/d0grEmGA=='}",
      "{'v1':'uhvRMFBry+/ZQem/8Iqoa4KMV3sANZWtbaVPHys5o6U=','k1':'sNDmW9X2Qvjy8/MU4Jus7g=='}",
      "{'v1':'0umNFby7oG73LJTSlR6IAHbtPSaOmCpjts/LhhkHefI=','k1':'qd54cJ12B8MUQb/kspD7ZA=='}",
      "{'v1':'RHt+FrlKHwSR8xyWJssRpkzK1It/XTLpvvY32OMSZUgxLOI6iz7I6JQQkwOGMPXh','k1':'74yfM29tTDoDeOaWm39EzQ=='}",
      "{'v1':'a6FuK0x60szgIKG1zJxuP72p5w9IsPfPYlkSHTKhQag=','k1':'7rWUIFrXJW3tLMUzkixwBQ=='}",
      "{'v1':'gZUjconBwyOxD7L10Ehi1ichKKgtlm6cMCO75CATFBalGaKU15ZAkE2pox4uQX8B','k1':'nYl4oXg9CVXQZYutOS83ig=='}",
      "{'v1':'2OGMTR4+V5Ax95gVseB2Gbx5P8OglJi0hSGC6w9DwAA=','k1':'W21SfW6D/3drhtSaoc20qQ=='}",
      "{'v1':'qoV/2nwbENau/QRXrjkdpwd5+UEgkVYpSUptQXcljQE=','k1':'kIa1ufo7zuZ8aDQwYKtDzQ=='}",
      "{'v1':'p0gvWfKOdbo2dQsnz0kEuqO71QfvmrdwIcT6Mhv5dzNxRSXKweJbAYprV/nSVJuO','k1':'iQCOUiYRPWWUdMkg2+2eHA=='}",
      "{'v1':'HkmwXiK0z3FQ70hVoKHfUlg/Ff3XAmWWUEg9wS2tifM=','k1':'MEu6d1Cx+x8SxRKqm5RLhw=='}",
      "{'v1':'TmrFsSpzJszvB4lsMDCCW0ReoAPPgYmdY8au+kI/jmM=','k1':'t3CR2a+OXVsjv1Jtqb5gGw=='}",
      "{'v1':'6uVu86q8WU61Ka6+h/MvulBqRZa3IWxO6W6usHsooStBh8/NClKcElKza0ZLGorF','k1':'XUR/kJxAPiV79qfYm0rAqg=='}",
      "{'v1':'xCbc1yBMBFKTquF3/D6ldd2giDYzfl+G2BEKSsA2Y2pk8ECidmjpQaeGVYp+Qrk+','k1':'iy5OAfn3O7vPzTYJYI9sxQ=='}",
      "{'v1':'AlgDMsJFFksU27JPkdxlV5/VKSQz3vf4/0UkubHIiZxQ9Ku+tZTfCgzGled4vtTe','k1':'9VdNIaAOghrtupoigi2nkw=='}",
      "{'v1':'KttKh9U9oWRN4Oo73/xaJPpbs3pob2klVaXx+Yip47o=','k1':'wRonlU5l43sM1FMdygK8iQ=='}",
      "{'v1':'igshAPa3ibn2wsmDKKMhX+fO73KWvNYt5OBBTtkwrck=','k1':'fZBQjP7bp9Lhu6jNZuTPKw=='}",
      "{'v1':'GQnglVPZXuN9ycVa99Ke3K7tfkLF1Kkj/yXRXvymYbc=','k1':'1n0rspevRumXAyrYWx0PKQ=='}"
    ];

    series = series.map((item) => {
      const parsedItem = JSON.parse(item.replace(/'/g, '"')); 
      // 解析密文和 IV
      const cipherText = CryptoJS.enc.Base64.parse(parsedItem.v1); 
      const iv = CryptoJS.enc.Base64.parse(parsedItem.k1); 
      // 使用固定密钥进行解密
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
      // 解密操作
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
      let plainText = decrypted.toString(CryptoJS.enc.Utf8);
      plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
      parsedItem.title = plainText;
      return parsedItem; // Return the modified item
    });
    setSeries(series);
    } catch (error) {
      console.error('Error fetching series:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSeries();
  }, []);

  if (loading) {
    return <LoadingText />;
  }

  if (error) {
    return <ErrorRetry title={'系列专辑'} onClick={fetchSeries} />;
  }

  return (
    <>
      <BannerAds />
      <GridAds/>
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">所有系列专辑列表</div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-3 xs:gap-3 xs:text-xs s:text-sm">
        {series.map((series, index) => (
          <Link key={index} to={`/series/${series.title}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">
            {series.title}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Series;
