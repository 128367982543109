import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Meta = ({ title, description, keywords }) => (
  <HelmetProvider>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  </HelmetProvider>
);

export default Meta;
