import React, { useState, useEffect } from 'react'; // 引入 useEffect
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import MetaBanner from '../components/MetaBanner';

const AdsDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    src,
    href = [],
    title,
    owner,
    detailsrc = [],
    helpsrc,
    buttontext,
  } = location.state || {};
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  // 确保 href 是数组
  const linksArray = Array.isArray(href) ? href : [href];

  // 如果没有 buttontext，默认使用“点击进入”
  const hasButtonText = Boolean(buttontext);
  const buttonTextArray = hasButtonText
    ? Array.isArray(buttontext)
      ? buttontext
      : [buttontext]
    : ['点击进入'];

  // 当 buttontext 不存在时，按钮数量为 1，链接为 href 数组的第一个元素
  const buttonCount = buttonTextArray.length;

  // 确保 carouselItems 至少有一个元素
  const carouselItems =
    detailsrc.length > 0 ? detailsrc.slice(0, 5) : [title];

  const openHelp = () => setIsHelpOpen(true);
  const closeHelp = () => setIsHelpOpen(false);
  const goBack = () => navigate(-1);

    // 设置动态标题
    useEffect(() => {
      document.title = `LD-${title}`;
    }, [owner]);


  // 当没有 buttontext 时，页面在 10ms 后自动跳转到 href 中的任意一个链接
  useEffect(() => {
    if (!hasButtonText) {
      const timer = setTimeout(() => {
        const randomLink = linksArray[Math.floor(Math.random() * linksArray.length)] || '#';
        window.location.href = randomLink;
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [hasButtonText, linksArray]);

  return (
    <div className="relative h-[90vh] bg-tb">
      {/* 区域 A - 导航区（固定顶部） */}
      <div className="fixed top-0 left-0 w-full h-[10%] flex items-center bg-tb text-tw px-6 z-10">
        {/* A1 */}
        <div className="w-[10%] flex justify-start">
          <button
            onClick={goBack}
            className="text-3xl font-bold focus:outline-none"
          >
            {'<'}
          </button>
        </div>
        {/* A2 */}
        <div className="w-[80%] flex justify-center">
          <h1 className="text-base font-bold">{title}</h1>
        </div>
        {/* A3 */}
        <div className="w-[10%] flex justify-end">
          <button
            onClick={openHelp}
            className="text-2xl focus:outline-none"
          >
            ?
          </button>
        </div>
      </div>

      {/* 区域 B - 轮播区 */}
      <div className="absolute top-[10%] left-0 right-0 flex justify-center items-center">
        <div className="w-full h-full flex justify-center items-center">
          <Swiper
            modules={[Autoplay]} // 启用 Autoplay
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            onSwiper={(swiper) => {
              document.querySelectorAll('video').forEach((video) => {
                // Check if swiper.autoplay is defined before trying to pause or resume it
                video.addEventListener('play', () => {
                  if (swiper.autoplay && swiper.autoplay.pause) {
                    swiper.autoplay.pause();
                  }
                });
                video.addEventListener('ended', () => {
                  if (swiper.autoplay && swiper.autoplay.resume) {
                    swiper.autoplay.resume();
                  }
                });
              });
            }}
            loop={carouselItems.length > 1}
            speed={200}
            allowTouchMove={true}
            style={{ '--swiper-transition-timing-function': 'ease-in-out' }}
          >
            {carouselItems.map((item, index) => {
              const extension = item.split('.').pop().toLowerCase();
              if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
                return (
                  <SwiperSlide key={index}>
                    <div className="flex items-center justify-center h-full">
                      <img
                        src={item}
                        alt={`Slide ${index}`}
                        className="object-contain max-h-full max-w-full"
                      />
                    </div>
                  </SwiperSlide>
                );
              } else if (extension === 'mp4') {
                return (
                  <SwiperSlide key={index}>
                    <div className="flex items-center justify-center h-full">
                      <video
                        controls
                        className="object-contain max-h-full max-w-full"
                      >
                        <source src={item} type="video/mp4" />
                        您的浏览器不支持视频标签。
                      </video>
                    </div>
                  </SwiperSlide>
                );
              } else {
                // 默认显示标题内容
                return (
                  <SwiperSlide key={index}>
                    <div className="flex items-center justify-center h-full">
                      <h2 className="text-2xl text-tw text-center px-4">{item}</h2>
                    </div>
                  </SwiperSlide>
                );
              }
            })}
          </Swiper>
        </div>
      </div>

      {/* 区域 C - 按钮区（固定底部） */}
      <div className="fixed bottom-0 left-0 w-full h-[20%] flex items-center justify-center bg-bg z-10">
        {/* 根据按钮数量动态渲染 */}
        {buttonCount === 1 && (
          <div className="flex justify-center items-center">
            <button
              onClick={() => window.location.href = linksArray[0] || '#'}
              className="m-2 w-32 h-12 bg-sc text-white rounded-md hover:bg-opacity-80 focus:outline-none"
            >
              {buttonTextArray[0]}
            </button>
          </div>
        )}
        {buttonCount === 2 && (
          <div className="flex justify-center items-center">
            <div className="flex space-x-4">
              {buttonTextArray.map((text, index) => (
                <button
                  key={index}
                  onClick={() => window.location.href = linksArray[index] || '#'}
                  className="m-2 w-32 h-12 bg-sc text-white rounded-md hover:bg-opacity-80 focus:outline-none"
                >
                  {text}
                </button>
              ))}
            </div>
          </div>
        )}
        {buttonCount === 3 && (
          <div className="flex flex-col justify-center items-center">
            <div className="flex space-x-4">
              {buttonTextArray.slice(0, 2).map((text, index) => (
                <button
                  key={index}
                  onClick={() => window.location.href = linksArray[index] || '#'}
                  className="m-2 w-32 h-12 bg-sc text-white rounded-md hover:bg-opacity-80 focus:outline-none"
                >
                  {text}
                </button>
              ))}
            </div>
            <div className="mt-2">
              <button
                onClick={() => window.location.href = linksArray[2] || '#'}
                className="m-2 w-32 h-12 bg-sc text-white rounded-md hover:bg-opacity-80 focus:outline-none"
              >
                {buttonTextArray[2]}
              </button>
            </div>
          </div>
        )}
        {buttonCount > 3 && (
          <div className="flex flex-wrap justify-center items-center">
            {buttonTextArray.map((text, index) => (
              <button
                key={index}
                onClick={() => window.location.href = linksArray[index] || '#'}
                className="m-2 w-32 h-12 bg-sc text-white rounded-md hover:bg-opacity-80 focus:outline-none"
              >
                {text}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Help 弹窗 */}
      {isHelpOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-tw bg-opacity-50 z-50"
          onClick={closeHelp}
        >
          <div
            className="bg-tb w-[90%] h-[75%] max-h-[75%] flex flex-col justify-center items-center rounded-lg relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeHelp}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none text-4xl"
            >
              ×
            </button>
            {/* 显示 helpsrc 内容 */}
            {helpsrc ? (
              <img
                src={helpsrc}
                alt="Help"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <p>暂无帮助内容</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdsDetail;