import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import {BannerAds,GridAds } from '../components/AdsComponent';

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchTags = async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
       let tags = [
        "{'v1':'zSPv8WCfNHS+2ugZ3Jaw/qO9t8pwvi52/DJ1Y5QxgHg=','k1':'xnqU89JTo/6R/u7hJ7amZQ=='}",
        "{'v1':'/ixT7q6o8ghQlfhL9IFZv5DHnI0B5cZX0ZPlk00tcoQ=','k1':'dLJcfkN+8YrNVfbDMyrBSw=='}",
        "{'v1':'+7mpZ2Jazuk3KVpZrurO8ZMn+d9wAqxrPmS25DtXV6o=','k1':'enOovj4CAzhNZyew38n6OQ=='}",
        "{'v1':'o6cBIVMd1Tayhl1hL4acjJSB8Yy8hjdoiJMMMhBWSGE=','k1':'vjccg4N8FhX2d/w7FC9PNg=='}",
        "{'v1':'vgcnWHWV9V0X0th8iuO8QwaRA0m+SrcSM8+RL3Lv5e8=','k1':'7UVqnyIctimnDUFwlJfVtg=='}",
        "{'v1':'smvWWCkAqPv0bIXpOWh23f68MWEnuOsRWeRZGTRFk+g=','k1':'tsasY872tyQ1Y34QOsC4Uw=='}",
        "{'v1':'iKCF+wmdWG6rapNyrLVNGU7a7JxM/8iw125uPr/vBE0=','k1':'2ZI91T083wsLKcmS0+/TgQ=='}",
        "{'v1':'AL2Rh7VLdHwTxODNq9qnJNL/HXJZp08I6AA4cFNGkb8=','k1':'X2Wgy83o1FHoEJfmTZGxLg=='}",
        "{'v1':'U4kbykunpD4RQYhFzmmSYQB1hJbo9t8IlYXxcWkIW7g=','k1':'wZZgc5B9fPlIbQ78HZMH3Q=='}",
        "{'v1':'dGLAAeMl0vYXz9Dii6hshW+ZR4/+kDPjx5G1vZtw2X0=','k1':'8u+6k0F3J9ovfx3YqB1Lig=='}",
        "{'v1':'Pe2a9H/9dH/69ZR6j4gC1i1iKinxyHuy7zsZuzCP0so=','k1':'FV7vxzDbvc8GdRSdlsJBZQ=='}",
        "{'v1':'arSawWUjYELCKCy3hxwwqga6AIoKSQnkQx1ESJx1QKQ=','k1':'ntQdG7KqTZ/63nG/M/oo6A=='}",
        "{'v1':'Kr7ejoGtRh/avKCQ0ftlaCIysEriadLsGjpzFyDl6TM=','k1':'amvhwZFyl2B2nsa6FbbmHg=='}",
        "{'v1':'SCwuBVPpGi49C6wms/2QOPEUgxNi3rXyYoU2YLsD1Z0=','k1':'JBJNIvLWaXYlJVEjJi2j5Q=='}",
        "{'v1':'qVQ1oVWcG6aLBWmGzXzZNH8Dxog2wkGDeFgr1qOhbKk=','k1':'e6ZsqhZeka/o1V46uxbfUQ=='}",
        "{'v1':'uZVYahpBv+++EiHlVVuPCweo7cp/ccyiM0qvoPPvxDk=','k1':'fs3DaU/RiFt8nVBBXkTa5g=='}",
        "{'v1':'MTKAckOAKCKMlBkciEE0wOFJCoh2vkYvc3Qlkl/wfQY=','k1':'vlX7xh5R0x4lhy2riaIJvQ=='}",
        "{'v1':'xbmY58HfSmpGP388SVl8yDg7geJMtTWMLfPkYND2voU=','k1':'2IdNKFkal1K4oXd0w/nVdg=='}",
        "{'v1':'0K4KpoRuavrNUhrRr31XW0P9V+gP5BJZ0rCf5xZB5Ag=','k1':'ADVmYbdUfTZKQAUOk2fzXw=='}",
        "{'v1':'MZ4yEiFYflk/juG/gcRIm4Dvk2YENk+iLP2/vWz4NLk=','k1':'9LlvrsuHWRmHTMDx+1PGqA=='}",
        "{'v1':'Fqvff9sHvjLW0xDi4QC6E0emE/BCBwOjwikX0/gQ6Rc=','k1':'6UWNuJEjqSYwYSIYbsru5w=='}",
        "{'v1':'nvvaOYxWVDJeyqiVbyxxWln+MKmvfsrjCsNgUOW1SCs=','k1':'gORXxKruggdnrKw7gpeVww=='}",
        "{'v1':'NLdDXSGGmITF7dYktB2TlwVlhGdUq4XdJcCAkJSov6w=','k1':'aiD+oyPhfTqxhk+fnJUXPA=='}",
        "{'v1':'379VqlYb+2D356SVBbiHX6DbXyHvverUKORtU2BYXEQ=','k1':'YxA4LHMza3plleNJSO6ccQ=='}",
        "{'v1':'JOpCCHnkYWgcYV68RcmwX/HeFlBWygCpbUucyVsmT8M=','k1':'AZVeNDYppqw9bYKFIklzyA=='}",
        "{'v1':'2iavElnTc4FOEGX3ujIY+Sl9DCTC75D80PSwiHw8/0s=','k1':'PSyouyLMifj3IWgNMjjuow=='}",
        "{'v1':'nxX8UNEhZTqwXv5q22TiE69lYt9+Mdye+W+P8ZHsBJs=','k1':'eo97ev6/NdHMfrrI0g8rlQ=='}",
        "{'v1':'Bui2AreoUzH2PO6ANQWPewWjKPhvvxYoAbCJEAiC8Fc=','k1':'lCCaiRWdpilkU+vm7+ofoQ=='}",
        "{'v1':'ju1u57ouaiZK8KI0bhDtsxX8pYQZIhZEdDwTPAWqeWk=','k1':'JHpmLOwV6wgM2/IUAPFjRg=='}",
        "{'v1':'ihgOaYi4e6a9RA21rG8v48j5Ufl2DnXcLyJtw0bqKsc=','k1':'slsxss7+wHVdJj3JK1qrPw=='}",
        "{'v1':'oQYqzfVYNOnHBHkw1S1Mjou1BL+imgrQXvE/6CmbMqQ=','k1':'fQe+nWYEqbMipcymk1RgzA=='}",
        "{'v1':'b7ZO0HtBtchpZTpjX3xEcXI2X4vPAgSLCro0E/ZULaI=','k1':'i7l9jzFIHazsM0PzKyVY0w=='}",
        "{'v1':'vmX4Fkp8SH6rl3RIJ8f8qQhwLx0Ra/vW7DdiZLaGr6M=','k1':'J9Tkvfpx6SAQ2DdKYfUeUQ=='}",
        "{'v1':'xHke1vLSFXL2JY+Z221gVMnP/ppiLdCbYvomQeFtJR0=','k1':'BemrHR9hchLKt8grP6ExlA=='}",
        "{'v1':'J1RSCCrHmO9FaXci5etTVMmkqx7G50D+Si8K1z81f1o=','k1':'NM5tBavJdb63jFSMzGP4nA=='}",
        "{'v1':'dn0kMu63I/lrimTyl5o7OBmjDdFdVzOC+8GJI6C4c1A=','k1':'mGXJ4ohYspEh0sNfL2lXCA=='}",
        "{'v1':'rr5byXO7CSH6QLdX20Qokrp+UtV+gvcRJ8sd/UFVfs4=','k1':'UKn85pISeccDl/GKhfPJZQ=='}",
        "{'v1':'wEfWA+4xD5zqMN4gPGEKm9RwYDr69gIa6DHTV1hIoHU=','k1':'R5RMPMxUvoPzn1XiubzwHg=='}",
        "{'v1':'znHOm6g8CKaGdcie9ZIusymI14FCVkyx556cES6+9s4=','k1':'vdKePFMdDH95XVKWl3o4QQ=='}",
        "{'v1':'68MrnXDpxAlaKpCyG9qxqw3yHUxD0DPyppqxH3addDk=','k1':'rRzkwfYWbF97c8gNFHhlxA=='}",
        "{'v1':'qsoNP3zgk9/69MIeydIErJV6kFbjPy8lZ7/lndtQFZo=','k1':'59b5ysuE6iJ1o6Up5bZv1g=='}",
        "{'v1':'2tYCbliPiVkidT1lLCRuqKx1J8PcXihbaCzjxfSylIs=','k1':'wqVv+iwZycwainhDYI9l4w=='}",
        "{'v1':'8wG846E2aXL/VIgP6OdN1gAIHma/3Mm0/ob/mD2RSHg=','k1':'NIxwa3TE361NwQzvOdCI2A=='}",
        "{'v1':'51DdNgW+gAnka6+q/ADJW/AQUUggp5EB7NokRt7I2aA=','k1':'Jq+UwmlkLhrpDbNu3i0LJQ=='}",
        "{'v1':'2ZamsgcLn06Ha4XJUQLaEyXi1SE+VYB3vnFr0kzpDhI=','k1':'75+B570Lm47HzdUUsaomYw=='}",
        "{'v1':'cQIUZWR78a8YxADc1V5tAOCttWMoMphUCnIS2l5ub8M=','k1':'3JwkxFSFzjgynjbyZYfrSQ=='}",
        "{'v1':'bOjf4IVJbdt6lJy8OeQqv7jRToI/Iu3Q5C4qC2s/teg=','k1':'ldX6iKUtY8VXJi0s6gQk1A=='}",
        "{'v1':'pOHLZlaj5dFBjljNDW5V+CkncM4BP3kijaem9uAlVI0=','k1':'6Xw5r+uupbL1QE7aw/5MRQ=='}",
        "{'v1':'RTrnKM70/g7CKdpGLJZssARx8/IILGsk9FIU3G+R49U=','k1':'his9WOr5vqrYPvM2PSqMJA=='}",
        "{'v1':'DcPY3LPqk2OyYB6HzKwO/envx8BlTVLIHyD5SfZ+fAs=','k1':'6hl4qIh40RPJ3XB5PT1Oyw=='}",
        "{'v1':'DyoDItcR6I2QprA5RAXnUAJ1McNkETzhrbInUioWWrvmFwAv6LFR6s28B5b0Oo/K','k1':'P9Mlzww/M7f9AgNr/BOsEg=='}",
        "{'v1':'xtLfepmfsxhEjJTNLbWAR5vK63ccpNalz5YvlWFfVUE=','k1':'fzkt5sroy0q5dVmpJfE+ug=='}",
        "{'v1':'Ekb4m43Hs6Gw/XyC+RmTiRJqiFi9xUxVb/neFa6khFU=','k1':'+2d2OZalmhIdRgnByYiL+w=='}",
        "{'v1':'kgAKe6ar0C7EDilwtl5ZZIJKwND+4WyFzBwxTTGVLEU=','k1':'zoQUHJQrJv3yLFqTHrCkgg=='}",
        "{'v1':'pK04VyEheJq5yTfiKUUxUJGY/9hxE1M/tFJVnhHDcfA=','k1':'0pWsCM/7eLLMs2WAZOtA+Q=='}",
        "{'v1':'ZjfuEIG84eIOO21q+W37lPYR41AcrHIz71mvBBAMF6M=','k1':'/bEe81o1XU6Z3Vcvc5NXEQ=='}"
      ]
      
      tags = tags.map((item) => {
        const parsedItem = JSON.parse(item.replace(/'/g, '"')); 
        // 解析密文和 IV
        const cipherText = CryptoJS.enc.Base64.parse(parsedItem.v1); 
        const iv = CryptoJS.enc.Base64.parse(parsedItem.k1); 
        // 使用固定密钥进行解密
        const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
        // 解密操作
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
        let plainText = decrypted.toString(CryptoJS.enc.Utf8);
        plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
        parsedItem.title = plainText;
        return parsedItem; // Return the modified item
      });

      setTags(tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  if (loading) {
    return <LoadingText />;
  }

  if (error) {
    return <ErrorRetry title={'标签'} onClick={fetchTags} />;
  }

  return (
    <>
      <BannerAds />
      <GridAds/>
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">所有标签列表</div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-3 xs:gap-3 xs:text-xs s:text-sm">
        {tags.map((tag, index) => (
          <Link key={index} to={`/tags/${tag.title}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">
            {tag.title}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Tags;
