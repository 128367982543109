import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import axios from 'axios';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import SearchPagination from '../components/SearchPagination';
import {BannerAds,GridAds } from '../components/AdsComponent';

const Search = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [triggerFetch, setTriggerFetch] = useState(false);

  const { query, p } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(query || '');
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);

  const fetchData = async (searchValue, page) => {
    setLoading(true);
    setError(false);

    try {
      const response = await axios.get(`/api/searchs?key=${searchValue}&p=${page}/`);
      if (response.status === 200) {
        let data = response.data.data;
        // 遍历并修改每一条数据
        let updatedData = data.map((item) => {
          // 解析密文和 IV
          let newItem = { ...item };
          const cipherText = CryptoJS.enc.Base64.parse(newItem.t1); 
          const iv = CryptoJS.enc.Base64.parse(newItem.t2); 
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          let jsonData = null;
          try {
            jsonData = JSON.parse(plainText); // 解析成 JSON
          } catch (e) {
            console.error("JSON 解析失败:", e);
          }
          if (jsonData) {
            newItem = { ...newItem, ...jsonData };
          }
          return newItem; // 返回修改后的 item
        });
        
        setData(updatedData || []);
        setTotalPage(response.data.page || 0);
        setIsLastPage(response.data.page === page);
      } else {
        console.error('Failed to fetch data:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > totalPage) {
      alert(`已超出最大页 ${totalPage}`);
    } else {
      setCurrentPage(newPage);
      navigate(`/search/${searchQuery}/${newPage}/`);
      fetchData(searchQuery, newPage);
    }
  };

  const handleSearch = () => {
    if (!inputValue.trim()) {
      alert('请输入搜索关键词');
    } else {
      setSearchValue(inputValue);
      setSearchQuery(inputValue);
      setCurrentPage(1);
      navigate(`/search/${inputValue}/1/`);
      fetchData(inputValue, 1);
    }
  };

  const handleSearchKey = (keyword) => {
    setInputValue(keyword);
    setTriggerFetch(true);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (searchQuery && currentPage) {
      fetchData(searchQuery, currentPage);
    }
  }, [searchQuery, currentPage]);

  useEffect(() => {
    if (triggerFetch) {
      handleSearch();
      setTriggerFetch(false);
    }
  }, [triggerFetch]);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <LoadingText />;
  }

  if (error) {
    return <ErrorRetry title={'推荐视频'} onClick={() => fetchData(searchQuery, currentPage)} />;
  }

  return (
    <>
      <Meta title="搜索-5G影院" description="欢迎来到5G影院，这里有最精彩的在线视频，每天更新永久免费" keywords="5G影院, 搜索, 在线视频, 每天更新, 永久免费" />
      <BannerAds />
      <GridAds/>
      <div className="flex items-center space-x-2">
        <input
          type="text"
          placeholder="请输入搜索内容"
          className="px-4 py-2 w-80 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          style={{ color: 'black' }}
          value={inputValue}
          onChange={handleInputChange}
        />
        <button
          className="px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50"
          style={{
            backgroundColor: 'rgb(51 51 51 / var(--tw-bg-opacity))', // Custom background color
            color: '#FFFFFF', // Text color
            borderRadius: '0.375rem',
            padding: '8px 16px',
            outline: 'none',
          }}
          onClick={handleSearch}
        >
          搜索
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 p-4">
        {['国产', '欧美', '日韩', '大陆'].map((keyword) => (
          <div
            key={keyword}
            className="border border-gray-300 p-2 text-center cursor-pointer"
            onClick={() => handleSearchKey(keyword)}
          >
            {keyword}
          </div>
        ))}
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 mt-4">
        {data.length > 0 ? (
          data.map((item) => (
            <div key={item.id} className="break-inside-avoid mb-4">
              <Link to={`/${item.id}/`} className="break-inside-avoid mb-4">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={`https://5gixb.xyz:1443/pic/${item.litpic}.webp`}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2">{item.title}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>没有找到结果</p>
        )}
      </div>
      {totalPage ? (
        <SearchPagination currentPage={Number(currentPage)} totalPage={totalPage} isLastPage={isLastPage} onPageChange={handlePageChange} />
      ) : null}
    </>
  );
};

export default Search;
